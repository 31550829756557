// MARK: React
import React from "react";

// MARK: Next
import Image from "next/image";

// MARK: Implementation
interface IProps {}

export const AbsoluteContactSocialButtons: React.FC<IProps> = () => {
	const onClickGTagEvent = (eventName: string) => {
		if (typeof gtag !== "undefined") {
			gtag("event", eventName, {
				event_category: "Site Forms",
			});
		}
	};

	return (
		<>
			<div className="containerSocialButton">
				<a
					className="socialButton"
					href="https://wa.me/+557181033219?text=Ol%C3%A1%21%20Gostaria%20de%20falar%20sobre%20um%20projeto%20de%20tecnologia."
					onClick={() => onClickGTagEvent("WhatsApp Button Clicked")}
					target="_blank"
					rel="noreferrer"
				>
					<Image src="/wpp.svg" alt="Whatsapp" height={58} width={58} />
				</a>
			</div>
		</>
	);
};
