// MARK: React
import React from "react";

// MARK: Next
import Head from "next/head";

// MARK: Implementation
export const SiteHead: React.FC = () => {
	const googleAnalyticsId = "UA-111279599-5";

	return (
		<Head>
			{/* MARK: App */}
			<meta
				key="viewport"
				name="viewport"
				content="width=device-width, initial-scale=1, shrink-to-fit=no"
			/>
			<link rel="apple-touch-icon" href="/icon-192.png" />
			{/* MARK: Analytics */}
			{process.env.APP_ENV === "production" ?
				(
					<>
						{/* tslint:disable: react-no-dangerous-html */}
						{/* Google */}
						<script async={true} src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`} />
						<script dangerouslySetInnerHTML={{ __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${googleAnalyticsId}');` }} />
						{/* tslint:enable: react-no-dangerous-html */}
					</>
				) :
				(
					<meta name="robots" content="noindex" />
				)
			}
		</Head>
	);
};
