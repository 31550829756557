// MARK: React
import React from "react";
import "../styles/index.scss";
import "./style.scss";

// MARK: Next
import App from "next/app";
import { default as Router } from "next/router";

// MARK: NProgress
import * as NProgress from "nprogress";
NProgress.configure({ showSpinner: false });
function nprogressStart() {
	if (typeof window !== "undefined") {
		NProgress.start();
	}
}

function nprogressEnd() {
	if (typeof window !== "undefined") {
		NProgress.done();
	}
}

Router.events.on("routeChangeStart", nprogressStart);
Router.events.on("routeChangeComplete", nprogressEnd);
Router.events.on("routeChangeError", nprogressEnd);

// MARK: Sentry
import SentryUtil from "../resources/SentryUtil";
const { captureException } = SentryUtil();

// MARK: Components
import { SiteHead } from "../components/SiteHead";

// MARK: API
import * as api from "@startapp/startapp-user-ssr-api";
api.setUrl("https://api.startapp.one/user");

// MARK: Lazy loading
import { lazyload } from "react-lazyload";

import { AbsoluteContactSocialButtons } from "../components/AbsoluteContactSocialButtons";
lazyload({ once: true, offset: 100 });

// MARK: Implementation
export default class MyApp extends App {
	public componentDidCatch(err: any, errInfo: any) {
		if (super.componentDidCatch) {
			super.componentDidCatch(err, errInfo);
		}

		captureException(err, errInfo);
	}

	public render() {
		const { Component, pageProps } = this.props;

		return (
			<>
				<SiteHead />
				<Component {...pageProps} />
				<AbsoluteContactSocialButtons />
			</>
		);
	}
}
